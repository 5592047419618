import { ChangeEvent, FC } from 'react'

interface IUploadInputProps {
    fileUploadHandler: (event: ChangeEvent<HTMLInputElement>, type: number) => void
    type: number
}

const UploadInput: FC<IUploadInputProps> = ({ fileUploadHandler, type }) => {
    return (
        <label htmlFor='file-upload'>
            <div className='group flex max-w-lg mx-auto justify-center cursor-pointer rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6 hover:border-lightPurple'>
                <div className='space-y-1 text-center'>
                    <svg
                        className='mx-auto h-12 w-12 text-gray-400'
                        stroke='currentColor'
                        fill='none'
                        viewBox='0 0 48 48'
                        aria-hidden='true'>
                        <path
                            d='M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02'
                            strokeWidth={2}
                            strokeLinecap='round'
                            strokeLinejoin='round'
                        />
                    </svg>
                    <div className='flex text-sm justify-center text-gray-600'>
                        <span className='text-center group-hover:text-gray-900'>Nahrajte soubor</span>
                        <input
                            id='file-upload'
                            name='file-upload'
                            type='file'
                            onChange={(e) => fileUploadHandler(e, type)}
                            className='sr-only'
                        />
                        {/*<p className='pl-1'>nebo drag and drop</p>*/}
                    </div>
                    <p className='text-xs text-gray-500'>PNG, JPG, PDF max do 10MB</p>
                </div>
            </div>
        </label>
    )
}

export default UploadInput
